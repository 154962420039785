:root{
  --dark-blue: #0E1D28;
  --bg-highlight-two: #FAFBFB;
  --bg-root: #f4f6f6;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: var(--bg-root) !important;
}

#root {
  height: 100%;
  margin: 0 auto;
  max-width: 2560px;
}

::selection {
  color: var(--bg-highlight-two);
  background-color: var(--dark-blue);
}

/* Disable arrows in number inputs */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
